import JsonSchema, {
    JsonSchemaArray,
    JsonSchemaBase,
    JsonSchemaString,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import { isString } from '@evidentid/json-schema/schemaChecks';

export function rollRandomOptional(): boolean {
    return Math.floor(Math.random() * 2) === 0;
}

export function getOptionalData(data: string | number | undefined, required?: boolean): string | number {
    return required || rollRandomOptional()
        ? data || ''
        : '';
}

export function getItemType(schema: JsonSchemaArray): string {
    return isString(schema.items as JsonSchema)
        ? (schema.items as JsonSchemaString).format || (schema.items as JsonSchemaString).type
        : (schema.items as JsonSchemaBase).type;
}
