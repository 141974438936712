import { JsonSchemaObject, JsonSchemaString, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';

export function buildEmailTemplateSchema(customProperties: string[]): JsonSchemaObject {
    const summarySchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        minLength: 1,
        title: 'Email subject',
        description: 'Enter subject',
    };

    const descriptionSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'html',
        minLength: 1,
        title: 'Email body text',
        description: 'Enter HTML body',
        variables: [
            'CLIENT:name',
            'CLIENT:displayName',
            'CLIENT:certificateHolderAddress',
            'RISK_PROFILE:requirements',
            'RISK_PROFILE:criteria',
            'ENTITY:contactEmail',
            'ENTITY:contactName',
            'ENTITY:contactPhoneNumber',
            'ENTITY:displayName',
            'ENTITY:DBAs',
            'ENTITY:legalName',
            'ENTITY:address',
            ...customProperties.map((name) => `ENTITY:customProperties.${name}`),
        ],
        htmlExtensions: {
            links: true,
        },
    };

    return {
        type: JsonSchemaType.object,
        properties: {
            summaryTemplate: summarySchema,
            descriptionTemplate: descriptionSchema,
        },
        required: [ 'summaryTemplate', 'descriptionTemplate' ],
    };
}
