<template>
    <Modal :additional-class-name="modalClasses" open @close="close">
        <template #header>
            Add/Edit Entity
        </template>
        <Alert v-if="alertConfig" :type="alertConfig.type">
            <template #title>
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                {{ alertConfig ? alertConfig.title : null }}
            </template>
        </Alert>
        <div v-if="form" class="AddIndividualEntityModal__inputSection">
            <JsonSchemaForm
                ref="formElement"
                v-model="value"
                :form="form"
                :disabled="saving"
                :custom-component-input="getCustomComponent"
                @submit="createEntity"
            />
        </div>

        <div class="AddIndividualEntityModal__actionSection">
            <Button
                type="primary"
                class="AddIndividualEntityModal__submitButton"
                :disabled="!valid"
                :loading="saving"
                @click="createEntity"
            >
                Save Entity
            </Button>
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch, Ref } from '@evidentid/vue-property-decorator';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import { JsonSchemaForm } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import createForm from '@evidentid/json-schema/createForm';
    import LoadingModal from '@/components/loading-modal/LoadingModal.vue';
    import {
        buildSingleEntityImportJsonSchema,
    } from '@/modules/entity-management/utils/build-entity-json-schema/buildEntityJsonSchema';
    import {
        filterEntityEmptyItems,
        hasPastExpirationDate,
        trimEntity,
    } from '@/modules/entity-management/utils/entityProcessing';
    import JsonSchemaConstForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaConstForm.vue';
    import { getJsonSchemaCompFunction } from '@/utils/getJsonSchemaCustomCompFuntion';
    import JsonSchemaCountryEnumForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/customElements/JsonSchemaCountryEnumForm.vue';
    import JsonSchemaAddressForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/customElements/JsonSchemaAddressForm.vue';
    import { removeNonStandardSchemaFormat } from '@/modules/decisioning-criteria/utils/removeNonStandardSchemaFormat';
    import omit from 'lodash/omit';
    import { CustomProperty, EntityInput } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        TprmSchemaDisplayFormat,
    } from '@evidentid/tprm-portal-lib/models/common/TprmSchemaDisplayFormat.model';
    import { TprmSchema } from '@evidentid/tprm-portal-lib/models/common/TprmSchema.model';

    interface EntityFormInput extends Omit<EntityInput, 'displayName' | 'legalName' | 'doingBusinessAs'> {
        insuredName: {
            displayName: string;
            legalName?: string | null;
            doingBusinessAs?: string[] | null;
        };
    }

    @Component({
        components: {
            Alert,
            Modal,
            Button,
            FormElement,
            FormInput,
            FontAwesomeIcon,
            JsonSchemaForm,
            LoadingModal,
        },
    })
    export default class AddIndividualEntityModal extends Vue {
        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        @Prop({ type: Array, default: () => [] })
        private requirementTypes!: string[];

        @Prop({ type: Object, default: null })
        private alertConfig!: { type: AlertType, title: string } | null;

        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        @Ref()
        private formElement!: JsonSchemaForm;

        private faExclamationTriangle = faExclamationTriangle;

        private form = null as any;
        private value: EntityFormInput = null as any;

        private customComponents = {
            [TprmSchemaDisplayFormat.hidden]: JsonSchemaConstForm,
            [TprmSchemaDisplayFormat.address]: JsonSchemaAddressForm,
            [TprmSchemaDisplayFormat.country]: JsonSchemaCountryEnumForm,
        };
        private getCustomComponent = getJsonSchemaCompFunction(this.customComponents);

        private get valid(): boolean {
            return this.form?.isValid(filterEntityEmptyItems(this.value, this.customProperties), true);
        }

        @Watch('customProperties', { immediate: true })
        private onCustomPropertiesUpdate(): void {
            const sortedRequirementTypes = [ ...this.requirementTypes ].sort();
            this.form = createForm(removeNonStandardSchemaFormat(buildSingleEntityImportJsonSchema(
                this.customProperties,
                sortedRequirementTypes,
                [ 'required', (entity) => entity.name.toLowerCase() ],
                [ 'desc', 'asc' ],
            )));
            this.value = this.form.getValue(undefined, true);
            this.setAddressCustomPropertiesDefaultCountryValue();
        }

        private get modalClasses(): string {
            return this.saving ? 'AddIndividualEntityModal AddIndividualEntityModal--saving' : 'AddIndividualEntityModal';
        }

        private close(): void {
            this.$emit('close');
        }

        private convertFormInputToEntityInput(entity: EntityFormInput): EntityInput {
            // flatten insuredName object that was used for view only
            return {
                ...entity.insuredName,
                ...omit(entity, 'insuredName'),
            };
        }

        private createEntity(): void {
            if (this.valid) {
                const trimmedAndFilteredFormEntity =
                    filterEntityEmptyItems(trimEntity(this.value), this.customProperties);
                const entity =
                    this.convertFormInputToEntityInput(this.form.getValue(trimmedAndFilteredFormEntity, true));
                this.$emit('submit', entity, hasPastExpirationDate(this.value));
            } else {
                // Do it after the form renders errors
                this.formElement.scrollToFirstError(true);
            }
        }

        private setAddressCustomPropertiesDefaultCountryValue(): void {
            if (this.value.insuredFields) {
                const customPropertiesValue = this.value.insuredFields;
                const isAddress = (field: CustomProperty) =>
                    (field.schema as TprmSchema).displayFormat === TprmSchemaDisplayFormat.address;
                const addressFieldKeys = this.customProperties.filter(isAddress).map((field) => field.key);
                addressFieldKeys.forEach((key: string) => {
                    const field = customPropertiesValue[key];
                    if (field && typeof field === 'object' && 'country' in field && !field.country) {
                        field.country = 'US';
                    }
                });
            }
        }
    }
</script>
