import { isObject } from '@evidentid/json-schema/schemaChecks';
import JsonSchema, { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
import { EntityStandardPropertyName } from '@/modules/entity-management/models/EntityStandardPropertyName.model';

function getObjectPropertyLabel(schema: JsonSchema, key: string): string {
    if (!isObject(schema)) {
        return '';
    }
    return (schema as JsonSchemaObject).properties[key]?.title || key;
}

function getObjectPropertyLabels(schema: JsonSchema): string[] {
    const props = Object.values((schema as JsonSchemaObject).properties);
    return props.map((prop) => `${prop.title}`);
}

function getObjectPropNames(schema: JsonSchema): string[] {
    return ('propertiesOrder' in schema &&
        schema.propertiesOrder &&
        schema.propertiesOrder.length > 0)
        ? schema.propertiesOrder
            .map((key) => getObjectPropertyLabel(schema, key))
        : getObjectPropertyLabels(schema);
}

export function getFlattenedStandardPropertyNames(properties: JsonFormProperty[]): string[] {
    const names = [] as string[];
    properties.forEach((property) => {
        if (property.name === EntityStandardPropertyName.address) {
            names.push(...getObjectPropNames(property.form.schema));
        } else {
            names.push(property.form.schema.title || property.name);
        }
    });
    return names;
}
