import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import defaultCoverageIcon from '@/modules/entity-details/assets/coverage-icon-default.svg';
import { uiRequirementTypeDetails } from '@/constants/uiRequirementTypeDetails.constant';

const requirementTypeIcons: Record<string, string> = uiRequirementTypeDetails.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.icon }),
    {},
);

export function getRequirementTypeIcon(coverageType: TprmRequirementType): string {
    return requirementTypeIcons[coverageType] || defaultCoverageIcon;
}
