import moment, { MomentSetObject } from 'moment';
import { SampleContact, SampleInsured, SpecialSampleType } from '@/modules/entity-management/utils/csv-sample/models';
import { getAddressPropOrder } from '@/modules/entity-management/utils/get-address-props-order/getAddressPropOrder';
import {
    buildBulkEntityImportJsonSchema,
} from '@/modules/entity-management/utils/build-entity-json-schema/buildEntityJsonSchema';

const dateFormat = 'MM/DD/YYYY';

export const sampleEntityNames: SampleInsured[] = [
    {
        displayName: 'Example Corp.',
        legalName: 'Example Corporation LLC',
        doingBusinessAs: 'The Example RiskProfile|Examples Incorporated|ExCorp',
        domain: '@example.com',
    },
    { displayName: 'Foo LLC', legalName: 'Foo Enterprises International Ltd', domain: '@foo.com' },
    { displayName: 'Company, Inc.', domain: '@company.com' },
];

export const sampleContactNames: SampleContact[] = [
    { name: 'Mary Espinoza', emailPrefix: 'mary' },
    { name: 'John Smith', emailPrefix: 'john.smith' },
    { name: 'Anna Faber', emailPrefix: 'annaf' },
    { name: 'Jimmie Douglas', emailPrefix: 'jimmie.n.douglas' },
    { name: 'Heather Johnson', emailPrefix: 'heather.johnson' },
];

export const sampleContactPhoneNumber: string[] = [
    '(559) 221-1838',
    '(719) 729-2449',
    '(984) 202-7721',
    '(586) 223-7450',
];

export const sampleExpirationDates: string[] = [
    moment().add(1, 'year').format(dateFormat),
    moment().set({ month: '11', date: '31' } as unknown as MomentSetObject).format(dateFormat),
    moment().add(1, 'year').set({ month: '4', date: '15' } as unknown as MomentSetObject).format(dateFormat),
    moment().add(1, 'year').set({ month: '11', date: '31' } as unknown as MomentSetObject).format(dateFormat),
];

export const sampleStrings: string[] = [
    'Lorem ipsum',
    'Aenean',
    'Vestibulum',
    'Nullam efficitur',
    'Praesent vulputate nibh',
];

export const sampleIntegers: string[] = [
    '1',
    '7',
    '10',
];

export const sampleNumbers: string[] = [
    '3.5',
    '7.8',
];

export const sampleBooleans: string[] = [
    'Yes',
    'No',
];

// TODO: we should make a source of truth of these labels and keys to avoid changes in multiple places
export const standardColumnNames: string[] = [
    'Display Name',
    'Legal Name',
    'DBA Name(s)',
    'Primary Contact Email',
    'Primary Contact Name',
    'Primary Contact Phone Number',
    'Country',
    'Street Address',
    'Apt, Suite or Unit',
    'City',
    'State',
    'Zip',
];

export const specialTypeSamplePool: SpecialSampleType = {
    collaterals: {
        description: [ 'Aquaeductus', 'Aratrum', 'Horreum', 'Plaustrum', 'Villae' ],
        category: [ ...sampleStrings ],
        uniqueIdentifier: [ '123456789', '1FVHG3DX3DHFE3747', 'CDEF-123', '7890-123-456' ],
        limitRequired: [ 15000, 50000, 150000, 275000 ],
        maximumDeductible: [ 150, 500, 1500, 2750 ],
    },
    address: [
        { street: '789 Oak Drive', city: 'Smallville', state: 'TX', zipCode: '98765', country: 'US' },
        { street: '321 Pine Lane', city: 'Rivertown', state: 'FL', zipCode: '56789', country: 'US' },
        { street: '987 Maple Street', city: 'Lakeside', state: 'GA', zipCode: '34567', country: 'US' },
        { street: '654 Birch Road', city: 'Mountainville', state: 'PA', zipCode: '76543', country: 'US' },
        { street: '321 Cedar Court', city: 'Seaside', state: 'WA', zipCode: '23456', country: 'US' },
        { street: '1234 First Street', city: 'Toronto', state: 'ON', zipCode: 'M1R 2T3', country: 'CA' },
        { street: '5678 Second Avenue', city: 'Vancouver', state: 'BC', zipCode: 'V6B 1A5', country: 'CA' },
        { street: '9012 Third Boulevard', city: 'Montreal', state: 'QC', zipCode: 'H3Z 2Y7', country: 'CA' },
    ],
};
