import { getAddressPropOrder } from '@/modules/entity-management/utils/get-address-props-order/getAddressPropOrder';
import { EntityStandardPropertyName } from '@/modules/entity-management/models/EntityStandardPropertyName.model';

function prefixStandardFieldObjectTypeCsvKeys(headerKeys: string[], objectKey: string): string[] {
    return headerKeys.map((key) => {
        if (getAddressPropOrder().includes(key)) {
            return `${objectKey}.${key}`;
        }
        return key;
    });
}

export function prefixStandardFieldAddressCsvKeys(headerKeys: string[]): string[] {
    return prefixStandardFieldObjectTypeCsvKeys(headerKeys, EntityStandardPropertyName.address);
}

export function appendStandardFieldAddressToObjectTypeKeysList(objectKeys: string[]): string[] {
    return [ EntityStandardPropertyName.address, ...objectKeys ];
}
