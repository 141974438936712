import { CustomProperty, EntityAddress } from '@evidentid/tprm-portal-lib/models/dashboard';
import { flatten, sample } from 'lodash';
import {
    sampleContactNames,
    sampleContactPhoneNumber,
    sampleEntityNames, sampleExpirationDates,
} from '@/modules/entity-management/utils/csv-sample/constants';
import {
    isCustomPropertyAddress,
} from '@/modules/entity-management/utils/is-custom-property-address/isCustomPropertyAddress';
import { getAddressPropOrder } from '@/modules/entity-management/utils/get-address-props-order/getAddressPropOrder';
import { getOptionalData, rollRandomOptional } from '@/modules/entity-management/utils/csv-sample/helpers/common';
import {
    generateSampleValue,
    generateSampleValues,
} from '@/modules/entity-management/utils/csv-sample/helpers/generateSampleValues';

export function generateSampleRow(fields: CustomProperty[], coverageTypes: string[] = []): (string | number)[] {
    const entityNames = sample(sampleEntityNames);
    const contact = sample(sampleContactNames);
    const phoneNumber = sample(sampleContactPhoneNumber);
    const values = generateSampleValues(entityNames || { displayName: 'Example Corp.', domain: '@example.com' });

    const sampleAddress = JSON.parse(values.specialTypes.address) as EntityAddress;
    const showData = rollRandomOptional();
    const address = getAddressPropOrder()
        .map((key) => showData && sampleAddress[key as keyof EntityAddress] || '');

    const valueToStrings = (field: CustomProperty) => {
        if (isCustomPropertyAddress(field)) {
            const showData = field.required || rollRandomOptional();
            return getAddressPropOrder()
                .map((key) => showData && sampleAddress[key as keyof EntityAddress] || '');
        } else {
            return [ getOptionalData(generateSampleValue(field, values), field.required) ];
        }
    };
    return [
        entityNames?.displayName || 'Example Corp.',
        entityNames?.legalName || '',
        entityNames?.doingBusinessAs || '',
        contact && entityNames ? `${contact.emailPrefix}${entityNames.domain}` : 'mary@example.com',
        contact?.name || '',
        phoneNumber || '',
        ...flatten(address),
        ...flatten(fields.map(valueToStrings)),
        ...flatten(coverageTypes.map(() => getOptionalData(sample(sampleExpirationDates)))),
    ];
}
