<template>
    <div role="form" class="BulkImportForm">
        <BoxList>
            <Box title="Step 1">
                Download the
                <a href="javascript:void(0)" class="nonbreak" @click="downloadTemplate">
                    <strong>
                        <FontAwesomeIcon :icon="faFileExcel" />
                        CSV worksheet</strong>
                </a>
                template to add your entities.
            </Box>
            <Box title="Step 2">
                Add your entity details such as company name, email address and primary contact to the downloaded worksheet.
            </Box>
            <Box title="Step 3">
                Once you’ve added all your entities, save the worksheet and drag and drop it below. Click import, and your entities will be added to your account.
            </Box>
        </BoxList>

        <div v-if="alertConfig">
            <Alert :type="alertConfig.type">
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                <div class="Alert__description">
                    {{ alertConfig.title }}
                </div>
            </Alert>
        </div>
        <FileListInput
            id="bulk-csv-upload"
            single-file
            :value="value"
            :disabled="disabled"
            accept="text/csv"
            :build-description="buildDescription"
            :multiple="false"
            @input="onFileListChange"
        >
            <div class="FileListInput__buttonContent">
                <FontAwesomeIcon :icon="faUpload" />
                <span>Drag your completed CSV worksheet here, or click to browse</span>
            </div>
        </FileListInput>
    </div>
</template>

<style lang="scss">
    .BulkImportForm {
        > :not(:last-child) {
            margin-bottom: 20px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faExclamationTriangle, faUpload, faFileExcel } from '@fortawesome/free-solid-svg-icons';
    import { FileListInput } from '@evidentid/dashboard-commons/components/FileListInput';
    import { BoxList, Box } from '@evidentid/dashboard-commons/components/BoxList';
    import { downloadSampleCsv } from '@/modules/entity-management/utils/csv-sample';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: {
            Alert,
            Box,
            BoxList,
            FileListInput,
            FontAwesomeIcon,
        },
    })
    export default class BulkImportForm extends Vue {
        private faExclamationTriangle = faExclamationTriangle;
        private faFileExcel = faFileExcel;
        private faUpload = faUpload;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Array, default: () => [] })
        private value!: File;

        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        @Prop({ type: Array, default: () => [] })
        private requirementTypes!: string[];

        @Prop({ type: Function, default: () => ({ description: '' }) })
        private buildDescription!: (file: File) => { description: string, error?: boolean };

        @Prop({ type: Object, default: null })
        private alertConfig!: { type: AlertType, title: string } | null;

        private onFileListChange(files: File[]): void {
            this.$emit('input', files);
        }

        private downloadTemplate(): void {
            downloadSampleCsv(this.customProperties, this.requirementTypes);
        }
    }
</script>
