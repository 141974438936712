import { SubmissionMethod } from '@evidentid/tprm-portal-lib/models/common/SubmissionMethod.model';
import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import {
    isRequirementWithoutDocumentUpload,
} from '@/modules/entity-details/utils/isRequirementWithoutDocumentUpload/isRequirementWithoutDocumentUpload';

export function isFieldImportApiOrWithoutDocumentUpload({
    submissionMethod,
    tprmRequirementType,
}: {
    submissionMethod?: SubmissionMethod;
    tprmRequirementType?: TprmRequirementType;
}): boolean {
    return submissionMethod === SubmissionMethod.FieldImportApi
        || (tprmRequirementType != null && isRequirementWithoutDocumentUpload(tprmRequirementType));
}
