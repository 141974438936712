<template>
    <div class="RiskProfileCriteriaTable">
        <Table>
            <template #head>
                <HeadCell class="RiskProfileCriteriaTable__criteriaNameHeader">
                    Criteria
                </HeadCell>
                <HeadCell class="RiskProfileCriteriaTable__verifyHeader">
                    Verify
                </HeadCell>
                <HeadCell class="RiskProfileCriteriaTable__conditionHeader">
                    Condition
                </HeadCell>
                <HeadCell class="RiskProfileCriteriaTable__requiredValueHeader">
                    Required Value(if applicable)
                </HeadCell>
                <HeadCell
                    v-if="someCustomPropertySubstitution"
                    class="RiskProfileCriteriaTable__substituteCustomProperty"
                >
                    Set Limit to Custom Property
                </HeadCell>
                <HeadCell class="RiskProfileCriteriaTable__actionsHeader" />
            </template>

            <RiskProfileCriteriaTableRow
                v-for="(criterion, index) of criteriaList"
                :key="index"
                :deletable="criteriaList.length > 1"
                :criterion="criterion"
                :template="templatesByFieldName[criterion.field]"
                :some-custom-property-substitution="someCustomPropertySubstitution"
                :criteria-can-custom-property-substitution="criteriaCanCustomPropertySubstitution"
                :custom-properties="customProperties"
                :accessed-reference-map="accessedReferenceMap"
                :is-reference-starts-with-is-invalid="isReferenceStartsWithIsInvalid"
                show-error
                @delete="onRowDelete(index)"
                @input="onRowInput($event, index)"
                @openSettings="$emit('openSettings', $event)"
                @reference-accessed="$emit('reference-accessed', $event)"
                @validityChange="$emit('validityChange', $event)"
            />
        </Table>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import RiskProfileCriteriaTableRow
        from '@/modules/decisioning-criteria/components/RiskProfileCriteriaTableRow/RiskProfileCriteriaTableRow.vue';
    import {
        IsReferenceStartsWithIsInvalid,
    } from '@/modules/decisioning-criteria/components/CreateEditRiskProfile/types';
    import { filterCustomPropertiesByValueType } from '@/modules/decisioning-criteria/utils/filterCustomPropertiesByValueType';
    import {
        getFlagDisabledReferenceValueType,
    } from '@/modules/decisioning-criteria/utils/getFlagDisabledReferenceValueType';
    import { CriteriaCanCustomPropertySubstitution } from './types';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        TprmSchemaDisplayFormat,
    } from '@evidentid/tprm-portal-lib/models/common/TprmSchemaDisplayFormat.model';
    import {
        Criterion,
        CriterionInput,
        CriterionTemplate,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

    @Component({
        components: {
            Button, HeadCell, Table, RiskProfileCriteriaTableRow,
        },
    })
    export default class RiskProfileCriteriaTable extends Vue {
        @Prop({ type: Array, default: () => [] })
        private criteriaList!: (CriterionInput | Criterion)[];

        @Prop({ type: Object, default: () => ({}) })
        private templatesByFieldName!: Record<string, CriterionTemplate>;

        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        @Prop({ type: Object, default: () => ({}) })
        private accessedReferenceMap!: Record<string, boolean>;

        @Prop({ type: Function as PropType<IsReferenceStartsWithIsInvalid>, required: true })
        private isReferenceStartsWithIsInvalid!: IsReferenceStartsWithIsInvalid;

        private get criteriaCanCustomPropertySubstitution(): CriteriaCanCustomPropertySubstitution {
            return this.criteriaList.reduce((result, criterion) => {
                if (this.canCriterionCustomPropertySubstitution(criterion)) {
                    result[criterion.field] = true;
                }

                return result;
            }, {} as CriteriaCanCustomPropertySubstitution);
        }

        private get someCustomPropertySubstitution(): boolean {
            return Object.keys(this.criteriaCanCustomPropertySubstitution).length > 0;
        }

        private canCriterionCustomPropertySubstitution(
            criterion: CriterionInput | Criterion,
        ): boolean {
            const template = this.templatesByFieldName[criterion.field];

            if (!template) {
                return false;
            }

            const conditionalRefs = template.displayMetadata.conditionalValueReference;

            if (!conditionalRefs) {
                return false;
            }

            const schema = criterion.evaluator.references[conditionalRefs.flagEnabledReference]?.schema;

            if (schema?.displayFormat !== TprmSchemaDisplayFormat.insuredField) {
                return false;
            }

            const type = getFlagDisabledReferenceValueType({
                template,
                criterion,
            });

            return filterCustomPropertiesByValueType(
                this.customProperties,
                type,
            ).length > 0;
        }

        private onRowDelete(index: number): void {
            this.$emit('input', [
                ...this.criteriaList.slice(0, index),
                ...this.criteriaList.slice(index + 1),
            ]);
        }

        private onRowInput(
            criterion: Criterion,
            index: number,
        ): void {
            this.$emit('input', [
                ...this.criteriaList.slice(0, index),
                criterion,
                ...this.criteriaList.slice(index + 1),
            ]);
        }
    }
</script>
