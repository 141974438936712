import startCase from 'lodash/startCase';
import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import {
    TprmRequirementModel,
} from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';
import { uiRequirementTypeDetails } from '@/constants/uiRequirementTypeDetails.constant';

const attributeAndRequirementTypeMap: Record<string, TprmRequirementType> = uiRequirementTypeDetails.reduce(
    (acc, curr) => {
        const attrNameVariations = curr.namesInAttribute.reduce((attrNameAcc, attrName) => ({
            ...attrNameAcc,
            [attrName]: curr.name,
        }), {});
        return { ...acc, ...attrNameVariations };
    },
    {},
);

export function getRequirementTypeVersionByAttribute(attr: string): string | null {
    const version = attr.split('.')[4];
    return /v\d+/.test(version) ? version : null;
}

export function getRequirementTypeByAttribute(attr: string): TprmRequirementType | null {
    const typePortion = attr.split('.')[3];
    return attributeAndRequirementTypeMap[typePortion] || null;
}

export function getRequirementTypeLabelByAttribute(
    attr: string,
    withVersion?: boolean,
    requirementTypeModels?: TprmRequirementModel[],
): TprmRequirementType | string | null {
    const requirementType = getRequirementTypeByAttribute(attr);
    if (!requirementType) {
        return null;
    }
    const typeModel = requirementTypeModels && requirementTypeModels.length > 0
        ? requirementTypeModels.find((model) => model.coverageType === requirementType)
        : null;
    const typeLabel = typeModel?.label || startCase(requirementType.toLowerCase());
    const version = getRequirementTypeVersionByAttribute(attr);
    return withVersion && version
        ? `${typeLabel} ${version}`
        : typeLabel;
}
