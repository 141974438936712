import { BaseEntityInput } from '@/modules/entity-management/types';
import {
    sampleContactPhoneNumber,
    sampleEntityNames,
    sampleStrings,
    specialTypeSamplePool,
} from '@/modules/entity-management/utils/csv-sample/constants';

function containsCollateralsData(value: any): boolean {
    const containsCollateralData = (val: any) => typeof value === 'object' && (
        // limitRequired and maxDeductible are number(all value has chance to be used), don't consider as sample
        specialTypeSamplePool.collaterals.description.includes(val.description) ||
        specialTypeSamplePool.collaterals.category.includes(val.category) ||
        specialTypeSamplePool.collaterals.uniqueIdentifier.includes(val.uniqueIdentifier)
    );
    return Array.isArray(value) && value.some(containsCollateralData);
}

function hasSpecialTypeSampleData(value: any): boolean {
    return containsCollateralsData(value);
}

export function isSampleEntity(insured: BaseEntityInput): boolean {
    const containsSampleEntityNames: boolean = sampleEntityNames.some((sampleInsured) =>
        sampleInsured.displayName === insured.displayName?.trim() ||
        insured.contactEmail?.includes(`${sampleInsured.domain}`));
    const containsSamplePhoneNumber: boolean = sampleContactPhoneNumber.some((number) =>
        `+1${number.replace(/\D/g, '')}` === insured.contactPhoneNumber);
    const containsSampleDomainInInsuredFields: boolean = sampleEntityNames.some((sampleInsured) =>
        (insured.insuredFields
            ? Object.values(insured.insuredFields).some((value) =>
                typeof value === 'string' && value.includes(sampleInsured.domain))
            : false));
    const containsSampleStringInInsuredFields: boolean = sampleStrings.some((sampleString) =>
        (insured.insuredFields
            ? Object.values(insured.insuredFields).some((value) =>
                typeof value === 'string' && value.includes(sampleString))
            : false));
    const containsSpecialTypeInsuredFields: boolean = insured.insuredFields
        ? Object.values(insured.insuredFields).some(hasSpecialTypeSampleData)
        : false;
    return containsSampleEntityNames || containsSamplePhoneNumber || containsSampleDomainInInsuredFields
        || containsSampleStringInInsuredFields || containsSpecialTypeInsuredFields;
}
