import orderBy from 'lodash/orderBy';
import { Many, ListIteratee } from 'lodash';
import JsonSchema, {
    JsonSchemaArray,
    JsonSchemaBasicObject,
    JsonSchemaObject,
    JsonSchemaType,
    RegularJsonSchema,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
import { entitySchemas } from '@/modules/entity-management/utils/build-entity-json-schema/constants';

export function buildEntityDetailsJsonSchema(
    customProperties: CustomProperty[],
    customPropertiesSortingIteratees: Many<ListIteratee<CustomProperty>> = (_, index) => index,
    customPropertyOrders: Parameters<typeof orderBy>[2] = []
): JsonSchemaBasicObject {
    const notSpaceOnlyRegex = '^(?!\\s+$)';
    const customPropertiesSchema = {
        type: JsonSchemaType.object,
        title: 'Custom Properties',
        properties: customProperties.reduce((accu, field) => {
            const property = {
                ...field.schema,
                // FIXME: a proper way to validate trimmed string for empty check
                ...(field.required && (field.schema as RegularJsonSchema).type === 'string' && { pattern: notSpaceOnlyRegex }),
            };
            return {
                ...accu,
                [field.key]:
                    (field.schema as RegularJsonSchema).type === JsonSchemaType.array && field.required
                        ? { ...property, minItems: 1 } as JsonSchemaArray
                        : property,
            };
        }, {}),
        propertiesOrder:
            orderBy(customProperties, customPropertiesSortingIteratees, customPropertyOrders)
                .map((x) => x.key),
        required: customProperties.filter((x) => x.required).map((x) => x.key),
    };
    return {
        type: JsonSchemaType.object,
        properties: {
            displayName: {
                type: JsonSchemaType.string,
                minLength: 1,
                placeholder: 'Enter product display name',
                pattern: '^(?!\\s+$)',
            },
            body: {
                type: JsonSchemaType.object,
                properties: {
                    profile: {
                        type: JsonSchemaType.object,
                        title: 'Properties',
                        properties: {
                            legalName: {
                                type: JsonSchemaType.string,
                                title: 'Legal Name',
                                description: 'The legal business name is the official name of the entity that appears on legal and government forms.',
                                pattern: notSpaceOnlyRegex,
                            },
                            doingBusinessAs: {
                                type: JsonSchemaType.array,
                                title: 'DBA Name(s)',
                                addItemTitle: 'ADD',
                                items: {
                                    type: JsonSchemaType.string,
                                    description: 'The “doing business as” (DBA) name is a different name the entity uses publicly instead of the legal name. ',
                                    minLength: 1,
                                    pattern: notSpaceOnlyRegex,
                                } as JsonSchema,
                            },
                            contactEmail: {
                                type: JsonSchemaType.string,
                                format: 'email',
                                minLength: 1,
                                title: 'Primary Contact Email',
                            },
                            contactName: {
                                type: JsonSchemaType.string,
                                title: 'Primary Contact Name',
                                description: 'Enter contact name',
                            },
                            contactPhoneNumber: {
                                type: JsonSchemaType.string,
                                format: 'phone',
                                title: 'Primary Contact Phone Number',
                            },
                            address: entitySchemas.address,
                        },
                        propertiesOrder: [
                            'legalName',
                            'doingBusinessAs',
                            'contactName',
                            'contactEmail',
                            'contactPhoneNumber',
                            'address',
                        ],
                        required: [
                            'contactEmail',
                        ],
                    },
                    ...(
                        customProperties && customProperties.length > 0 &&
                        { insuredFields: customPropertiesSchema as unknown as JsonSchemaObject }
                    ),
                },
                propertiesOrder: [
                    'profile',
                    customProperties && customProperties.length > 0 ? 'insuredFields' : null,
                ].filter(Boolean),
                required: [
                    'profile',
                    customProperties && customProperties.length > 0 ? 'insuredFields' : null,
                ].filter(Boolean),
            },
        },
        propertiesOrder: [
            'displayName',
            'body',
        ],
        required: [
            'displayName',
            'body',
        ],
    } as JsonSchemaBasicObject;
}
