<template>
    <div class="PolicyInfoGeneric">
        <div v-for="name in renderingList" :key="name">
            <PolicyInfoGenericRecord
                :requirement="requirement"
                :name="name"
                :values="values"
                :evaluation-errors="evaluationErrors"
                :tprm-fields-schema-map="tprmFieldsSchemaMap"
                :multi-field-criterion-map="multiFieldCriterionMap"
                :show-criterion-index="false"
                :show-criterion-field-index="true"
                :multi-field-order-map="multiFieldsCriterionFieldOrderMap"
                no-object-index
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
        </div>
        <PolicyInfoNonExtraction
            :get-evaluation-result="getEvaluationResult"
            :non-extraction-results="nonExtractionResults"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
    </div>
</template>

<script lang="ts">
    import { Prop, Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import difference from 'lodash/difference';
    import mapValues from 'lodash/mapValues';
    import JsonSchema, {
        JsonSchemaBasicObject,
        JsonSchemaType,
    } from '@evidentid/json-schema/interfaces/JsonSchema';
    import {
        EvaluationResult,
    } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { EntityMultiFieldCriterion } from '@/modules/entity-details/types';
    import {
        transformValuesWithIsPresent,
    } from '@/modules/entity-details/utils/transformValuesWithIsPresent/transformValuesWithIsPresent';
    import { getBasicSchemaByValue } from '@/modules/entity-details/utils/jsonSchemaUtilities';
    import {
        getEvaluationResultsFromEvaluationErrors,
        getMultiFieldCriteria,
        shouldOmitFieldsForRendering,
        getFieldOrderByRequirementType,
        getMultiFieldsOrderByRequirementType,
        parseFieldValue,
    } from '../../utils';
    import PolicyInfoNonExtraction
        from '@/modules/entity-details/components/Policy/sub-components/PolicyInfoNonExtraction/PolicyInfoNonExtraction.vue';
    import PolicyInfoGenericRecord
        from '@/modules/entity-details/components/Policy/sub-components/PolicyInfoGenericRecord/PolicyInfoGenericRecord.vue';
    import {
        NonExtractionResults,
    } from '@evidentid/tprm-portal-lib/models/entity-actions-review/actions/non-extraction-resolution/NonExtractionResults.model';
    import {
        EntityRequirement,
        EntityRequirementDetails,
    } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

    export default Vue.extend({
        name: 'PolicyInfoGeneric',
        components: {
            PolicyInfoGenericRecord,
            PolicyInfoNonExtraction,
        },
        props: {
            requirement: {
                type: Object as PropType<EntityRequirement>,
                required: true,
            },
            requirementModel: {
                type: Object as PropType<TprmRequirementModel>,
            },
            evaluationErrors: {
                type: Object as PropType<Record<string, FieldEvaluationResultError>>,
                default: () => ({}),
            },
            evaluationResults: {
                type: Array as PropType<EvaluationResult[]>,
                default: () => [],
            },
            allRequirements: {
                type: Array as PropType<EntityRequirementDetails[]>,
                default: () => [],
            },
            nonExtractionResults: {
                type: Object as PropType<NonExtractionResults >,
                default: () => ({}),
            },
        },
        computed: {
            defaultFieldAndCriterionList(): string[] {
                return [
                    ...Object.keys(this.tprmFieldsSchemaMap),
                    ...Object.keys(this.multiFieldCriterionMap),
                ];
            },
            predefinedSortOrder(): string[] {
                return getFieldOrderByRequirementType(this.requirement.coverageType);
            },
            multiFieldsCriterionFieldOrderMap(): Record<string, string[]> {
                return getMultiFieldsOrderByRequirementType(this.requirement.coverageType);
            },
            renderingList(): string[] {
                const alphabeticalSortOrder =
                    difference(this.defaultFieldAndCriterionList, this.predefinedSortOrder).sort();
                return [ ...this.predefinedSortOrder, ...alphabeticalSortOrder ];
            },
            categorizedEnumLabels(): CategorizedEnumLabels {
                return this.$store.state.dashboard.categorizedEnumLabels;
            },
            values(): Record<string, any> {
                const _values = Object.entries(this.requirement.details)
                    .reduce<Record<string, any>>(
                        (result, [ key, value ]) => {
                            if (!shouldOmitFieldsForRendering(
                                key,
                                [],
                                Boolean(this.multiFieldCriteriaFieldMap[key],
                                ),
                            )) {
                                result[key] = parseFieldValue(key, value, {
                                    requirement: this.requirement,
                                    requirementModel: this.requirementModel,
                                    evaluationResults: this.evaluationResults,
                                    evaluationErrors: this.evaluationErrors,
                                    multiFieldCriteria: this.multiFieldCriteria,
                                    categorizedEnumLabels: this.categorizedEnumLabels,
                                });
                            }
                            return result;
                        }, {});
                return transformValuesWithIsPresent(_values);
            },
            multiFieldCriteriaFieldMap(): Record<string, unknown> {
                return this.multiFieldCriteria.reduce((acc, item) => (
                    { ...acc, ...item.fields }
                ), {});
            },
            multiFieldCriterionMap(): { [key: string]: EntityMultiFieldCriterion } {
                return this.multiFieldCriteria
                    .reduce((acc, item) => ({ ...acc, [item.name]: item }), {});
            },
            multiFieldCriteria(): EntityMultiFieldCriterion[] {
                return getMultiFieldCriteria(
                    this.evaluationResults, this.evaluationErrors, this.allRequirements, this.categorizedEnumLabels,
                );
            },
            tprmFieldsSchemaMap(): { [key: string]: JsonSchemaBasicObject } {
                // convert each schema to object, rendered individually as JsonSchemaView expects object schema
                return mapValues(
                    getBasicSchemaByValue(this.values).properties,
                    (property: JsonSchema, key: string) => ({
                        type: JsonSchemaType.object,
                        properties: { [key]: property },
                    }));
            },

        },
        methods: {
            goToCriterion(riskProfileId: string): void {
                this.$emit('goToCriterion', riskProfileId, this.requirement.coverageType);
            },
            grantExceptionToCriterion(criterionId: string): void {
                this.$emit('grantExceptionToCriterion', criterionId);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('removeExceptions', exceptionIds);
            },
            getEvaluationResult(url: string): FieldEvaluationResultError | null {
                return getEvaluationResultsFromEvaluationErrors(url, this.evaluationErrors, this.requirement);
            },
        },
    });
</script>
