import { uiRequirementTypeDetails } from '@/constants/uiRequirementTypeDetails.constant';
import { UiRequirementTypeCategory } from '@/modules/entity-details/models/UiRequirementTypeDetails.model';
import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';

const requirementsWithoutDocumentUpload: TprmRequirementType[] = uiRequirementTypeDetails
    .filter((requirement) => requirement.category === UiRequirementTypeCategory.withoutDocumentUpload)
    .map((requirement) => requirement.name);

export function isRequirementWithoutDocumentUpload(tprmRequirementType: TprmRequirementType): boolean {
    return requirementsWithoutDocumentUpload.includes(tprmRequirementType);
}
