import orderBy from 'lodash/orderBy';
import { Many, ListIteratee } from 'lodash';
import { JsonSchemaBasicObject, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
import { entitySchemas } from './constants';
import { getCustomPropertySchema, getExceptionsSchema } from './helpers';

const commonPropertiesOrder = [
    'contactEmail',
    'contactName',
    'contactPhoneNumber',
    'address',
    'insuredFields',
    'exceptions',
];
const commonRequiredFields = [
    'contactEmail',
    'insuredFields',
    'exceptions',
];

export function buildSingleEntityImportJsonSchema(
    customProperties: CustomProperty[],
    requirementTypes: string[] = [],
    customPropertiesSortingIteratees: Many<ListIteratee<CustomProperty>> = (_, index) => index,
    customPropertyOrders: Parameters<typeof orderBy>[2] = []
): JsonSchemaBasicObject {
    return {
        type: JsonSchemaType.object,
        properties: {
            insuredName: {
                type: JsonSchemaType.object,
                title: 'Name(s)',
                properties: {
                    displayName: entitySchemas.displayName,
                    legalName: entitySchemas.legalName,
                    doingBusinessAs: entitySchemas.doingBusinessAs,
                },
                required: [ 'displayName' ],
                propertiesOrder: [ 'displayName', 'legalName', 'doingBusinessAs' ],
            },
            contactEmail: entitySchemas.contactEmail,
            contactName: entitySchemas.contactName,
            contactPhoneNumber: entitySchemas.contactPhoneNumber,
            address: entitySchemas.address,
            insuredFields: getCustomPropertySchema(
                customProperties, customPropertiesSortingIteratees, customPropertyOrders
            ),
            exceptions: getExceptionsSchema(requirementTypes),
        },
        propertiesOrder: [ 'insuredName', ...commonPropertiesOrder ],
        required: [ 'insuredName', ...commonRequiredFields ],
    };
}

export function buildBulkEntityImportJsonSchema(
    customProperties: CustomProperty[],
    requirementTypes: string[] = [],
): JsonSchemaBasicObject {
    return {
        type: JsonSchemaType.object,
        properties: {
            displayName: entitySchemas.displayName,
            legalName: entitySchemas.legalName,
            doingBusinessAs: entitySchemas.doingBusinessAs,
            contactEmail: entitySchemas.contactEmail,
            contactName: entitySchemas.contactName,
            contactPhoneNumber: entitySchemas.contactPhoneNumber,
            address: entitySchemas.address,
            insuredFields: getCustomPropertySchema(customProperties),
            exceptions: getExceptionsSchema(requirementTypes),
        },
        propertiesOrder: [ 'displayName', 'legalName', 'doingBusinessAs', ...commonPropertiesOrder ],
        required: [ 'displayName', ...commonRequiredFields ],
    };
}
