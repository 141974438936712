import JsonSchema, { JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import { COUNTRIES_ISO_3166_1 } from '@/modules/entity-management/constants/countryCodes';
import { TprmSchema, TprmSchemaDisplayFormat } from '@evidentid/tprm-portal-lib/models/common';
import { getAddressPropOrder } from '@/modules/entity-management/utils/get-address-props-order/getAddressPropOrder';

const addressSchema: TprmSchema = {
    type: JsonSchemaType.object,
    title: 'Address',
    $schema: 'http://json-schema.org/draft-07/schema#',
    required: [ 'country', 'city', 'street', 'zipCode' ],
    properties: {
        country: {
            enum: [ ...COUNTRIES_ISO_3166_1.map((x) => x.code) ],
            enumLabels: [ ...COUNTRIES_ISO_3166_1.map((x) => x.label || x.name) ],
            title: 'Country',
            maxLength: 2,
            minLength: 1,
            displayFormat: TprmSchemaDisplayFormat.country,
        } as TprmSchema,
        street: { type: JsonSchemaType.string, title: 'Street Address', minLength: 1 },
        aptSuiteOrUnit: { type: JsonSchemaType.string, title: 'Apt, Suite or Unit', minLength: 1 },
        city: { type: JsonSchemaType.string, title: 'City', minLength: 1 },
        state: { type: JsonSchemaType.string, title: 'State', minLength: 1 },
        zipCode: { type: JsonSchemaType.string, title: 'Zip', minLength: 1 },
    },
    displayFormat: TprmSchemaDisplayFormat.address,
    propertiesOrder: getAddressPropOrder(),
    if: {
        required: [ 'country' ],
        properties: { country: { enum: [ 'US', 'CA' ] } },
    },
    else: {},
    then: { required: [ 'state' ] },
};

export const entitySchemas: Record<string, JsonSchema> = {
    displayName: {
        type: JsonSchemaType.string,
        minLength: 1,
        title: 'Display Name',
        description: 'The display name is a custom name unique to this entity.',
        placeholder: 'Type Entity Display Name',
        // FIXME: a proper way to validate trimmed string for empty check
        pattern: '^(?!\\s+$)',
    },
    legalName: {
        type: JsonSchemaType.string,
        title: 'Legal Name',
        description: 'The legal business name is the official name of the entity that appears on legal and government forms.',
        placeholder: 'Type Entity Legal Name',
        pattern: '^(?!\\s+$)',
    },
    doingBusinessAs: {
        type: JsonSchemaType.array,
        title: 'DBA Name(s)',
        addItemTitle: 'ADD DBA',
        items: {
            type: JsonSchemaType.string,
            description: 'The “doing business as” (DBA) name is a different name the entity uses publicly instead of the legal name. ',
            placeholder: 'Type Entity Doing Business Name',
            minLength: 1,
            pattern: '^(?!\\s+$)',
        } as JsonSchema,
    },
    contactEmail: {
        type: JsonSchemaType.string,
        format: 'email',
        minLength: 1,
        title: 'Primary Contact Email',
        placeholder: 'Type entity email',
    },
    contactName: {
        type: JsonSchemaType.string,
        title: 'Primary Contact Name',
        placeholder: 'Type contact name',
    },
    contactPhoneNumber: {
        type: JsonSchemaType.string,
        format: 'phone',
        title: 'Primary Contact Phone Number',
    },
    address: addressSchema,
};
