<template>
    <div class="PolicyInfoGenericRecord">
        <JsonSchemaView
            v-if="getFieldSchemaByName(name)"
            :id="`#/${requirement.coverageType}/details`"
            class="PolicyInfo__jsonSchemaView"
            :schema="getFieldSchemaByName(name)"
            :value="values"
            :evaluation-errors="evaluationErrors"
            :no-object-index="noObjectIndex"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <MultiFieldCriterion
            v-else-if="getMultiFieldCriterionByTitle(name)"
            :criterion="getMultiFieldCriterionByTitle(name)"
            :field-order-list="multiFieldOrderMap[name]"
            :show-criterion-index="showCriterionIndex"
            :show-field-index="showCriterionFieldIndex"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
    </div>
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import JsonSchema, { JsonSchemaBasicObject } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { EntityMultiFieldCriterion } from '@/modules/entity-details/types';
    import { JsonSchemaView } from '@/modules/entity-details/components/JsonSchemaView';
    import MultiFieldCriterion from '@/modules/entity-details/components/MultiFieldCriterion/MultiFieldCriterion.vue';
    import { EntityRequirement } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';

    export default Vue.extend({
        name: 'PolicyInfoGenericRecord',
        components: {
            MultiFieldCriterion,
            JsonSchemaView,
        },
        props: {
            requirement: {
                type: Object as PropType<EntityRequirement>,
                required: true,
            },
            name: {
                type: String as PropType<string>,
                required: true,
            },
            values: {
                type: Object as PropType<any>,
                required: true,
            },
            tprmFieldsSchemaMap: {
                type: Object as PropType<Record<string, JsonSchemaBasicObject>>,
                required: true,
            },
            multiFieldCriterionMap: {
                type: Object as PropType<Record<string, EntityMultiFieldCriterion>>,
                required: true,
            },
            evaluationErrors: {
                type: Object as PropType<Record<string, FieldEvaluationResultError>>,
                default: () => ({}),
            },
            multiFieldOrderMap: {
                type: Object as PropType<Record<string, string[]>>,
                default: () => ({}),
            },
            noObjectIndex: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            showCriterionIndex: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            showCriterionFieldIndex: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        methods: {
            grantExceptionToCriterion(criterionId: string): void {
                this.$emit('grantExceptionToCriterion', criterionId);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('removeExceptions', exceptionIds);
            },
            goToCriterion(riskProfileId: string): void {
                this.$emit('goToCriterion', riskProfileId, this.requirement.coverageType);
            },
            getFieldSchemaByName(name: string): JsonSchema | null {
                return this.tprmFieldsSchemaMap[name] || null;
            },
            getMultiFieldCriterionByTitle(title: string): EntityMultiFieldCriterion | null {
                return this.multiFieldCriterionMap[title] || null;
            },
        },
    });
</script>
