import {
    UiRequirementTypeCategory,
    UiRequirementTypeDetails,
} from '@/modules/entity-details/models/UiRequirementTypeDetails.model';
import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';

import aviationLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-aviation-liability.svg';
import baileesInsuranceIcon from '@/modules/entity-details/assets/coverage-icon-bailees-insurance.svg';
import boatownersIcon from '@/modules/entity-details/assets/coverage-icon-boatowners.svg';
import businessAutoIcon from '@/modules/entity-details/assets/coverage-icon-business-auto-liability.svg';
import cargoLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-cargo-liability.svg';
import collectivePersonalAccidentInsuranceIcon from '@/modules/entity-details/assets/collective-personal-accident-insurance.svg';
import commercialAutoPhysicalDamageIcon from '@/modules/entity-details/assets/coverage-icon-commercial-auto-physical-damage.svg';
import commercialCrimeIcon from '@/modules/entity-details/assets/coverage-icon-commercial-crime.svg';
import commercialPropertyIcon from '@/modules/entity-details/assets/coverage-icon-commercial-property.svg';
import commercialTrailerInterchangeIcon from '@/modules/entity-details/assets/coverage-icon-commercial-trailer-interchange.svg';
import contingentAutoLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-contingent-auto-liability.svg';
import contingentCargoLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-contingent-cargo-liability.svg';
import coverageIconBuildersRisk from '@/modules/entity-details/assets/coverage-icon-builders-risk.svg';
import coverageIconCrop from '@/modules/entity-details/assets/coverage-icon-crop.svg';
import coverageIconDwelling from '@/modules/entity-details/assets/coverage-icon-dwelling.svg';
import coverageIconFarmOwners from '@/modules/entity-details/assets/coverage-icon-farm-owners.svg';
import coverageIconFleet from '@/modules/entity-details/assets/coverage-icon-fleet.svg';
import coverageIconFloodInsurance from '@/modules/entity-details/assets/coverage-icon-flood-insurance.svg';
import coverageIconHomeOwners from '@/modules/entity-details/assets/coverage-icon-home-owners.svg';
import coverageIconLifeInsurance from '@/modules/entity-details/assets/coverage-icon-life-insurance.svg';
import coverageIconLossOfIncome from '@/modules/entity-details/assets/coverage-icon-loss-of-income.svg';
import craneLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-crane-liability.svg';
import cyberLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-cyber-liability.svg';
import declarationsPageIcon from '@/modules/entity-details/assets/coverage-icon-declarations-page.svg';
import defaultIcon from '@/modules/entity-details/assets/coverage-icon-default.svg';
import directorsAndOfficersLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-directors-and-officers-liability.svg';
import earthquakeInsuranceIcon from '@/modules/entity-details/assets/coverage-icon-earthquake-insurance.svg';
import employeeDishonestyIcon from '@/modules/entity-details/assets/coverage-icon-employee-dishonesty.svg';
import employerLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-employers-liability.svg';
import employmentPracticesLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-employment-practices-liability.svg';
import environmentalLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-environmental-liability.svg';
import estheticianLicenseIcon from '@/modules/entity-details/assets/coverage-icon-esthetician-license.svg';
import garageKeepersLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-garage-keepers-liability.svg';
import garageLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-garage-liability.svg';
import generalLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-commercial-general-liability.svg';
import holdHarmlessAgreementIcon from '@/modules/entity-details/assets/coverage-icon-hold-harmless-agreement.svg';
import hullAndMachineryIcon from '@/modules/entity-details/assets/coverage-icon-hull-and-machinery.svg';
import inlandMarineCommercialAutoIcon from '@/modules/entity-details/assets/coverage-icon-inland-marline-commercial-auto.svg';
import leasedOrRentedEuipmentIcon from '@/modules/entity-details/assets/coverage-icon-leased-or-rented-equipment.svg';
import liquorLiabilityIcon from '@/modules/entity-details/assets/requirement-icon-liquor-liability.svg';
import longshoreAndHarborWorkersCompensationIcon from '@/modules/entity-details/assets/coverage-icon-longshore-and-harbor-workers-compensation.svg';
import marineGeneralLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-marine-general-liability.svg';
import maritimeEmployersLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-maritime-employers-liability.svg';
import massageTherapistLicenseIcon from '@/modules/entity-details/assets/coverage-icon-massage-therapist-license.svg';
import mediaLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-media-liability.svg';
import medicalErrorsAndOmissionsIcon from '@/modules/entity-details/assets/coverage-icon-medical-errors-and-omissions.svg';
import nonTruckingLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-non-trucking-liability.svg';
import occupationalIcon from '@/modules/entity-details/assets/coverage-icon-occupational.svg';
import personalLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-personal-liability.svg';
import privatePassengerAutoIcon from '@/modules/entity-details/assets/coverage-icon-private-passenger-auto.svg';
import productsAndCompletedOperationsIcon from '@/modules/entity-details/assets/coverage-icon-products-and-completed-operations.svg';
import professionalLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-professional-liability.svg';
import proofOfPaymentIcon from '@/modules/entity-details/assets/coverage-icon-proof-of-payment.svg';
import protectionAndIndemnity from '@/modules/entity-details/assets/coverage-icon-protection-and-indemnity.svg';
import publicAndProductsLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-public-and-products-liability.svg';
import riggersLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-riggers-liability.svg';
import scheduleOfFormsAndEndorsementsIcon from '@/modules/entity-details/assets/coverage-icon-schedule-of-forms-and-endorsements.svg';
import sexualAbuseAndMolestationLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-sextual-abuse-and-molestation.svg';
import studentAccidentMedicalIcon from '@/modules/entity-details/assets/coverage-icon-student-accident-medical.svg';
import suretyBondIcon from '@/modules/entity-details/assets/coverage-icon-surety-bond.svg';
import technologyLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-technology-liability.svg';
import thirdPartyFidelityBondIcon from '@/modules/entity-details/assets/coverage-icon-3rd-party-fidelity-bond.svg';
import umbrellaExcessIcon from '@/modules/entity-details/assets/coverage-icon-umbrella-excess.svg';
import w9FormIcon from '@/modules/entity-details/assets/coverage-icon-w-9-form.svg';
import warehouseLegalLiabilityIcon from '@/modules/entity-details/assets/coverage-icon-warehouse-legal-liability.svg';
import watercraftIcon from '@/modules/entity-details/assets/coverage-icon-watercraft.svg';
import workersCompensationIcon from '@/modules/entity-details/assets/coverage-icon-workers-compensation.svg';
import workersCompensationWaiverIcon from '@/modules/entity-details/assets/coverage-icon-workers-compensation-waiver.svg';
import yachtInsuranceIcon from '@/modules/entity-details/assets/coverage-icon-yacht-insurance.svg';

/*
 * In order to introduce a new Requirement, we need to
 * 1. add icon to assets folder (under /modules/entity-details/assets)
 * 2. add enum to TprmRequirementType (tprm-portal-lib/models/entity-details/TprmRequirementType.ts)
 * 3. add an entry in this constant list.
 *   - the name is the enum of the requirement that BE defines
 *   - the names in attribute is used to match requirement within attribute name this is used for Document displaying
 *   - label is currently not in use as we get that from another BE api, but just added for future reference
 *   - category is used to determine what specialized view to display within entityDetails page
 *   - icon will be used for requirement display on entity details as well as for risk profile configuration
 * 4. don't forget Evident Network Portal as currently these two apps needs to be updated separately.
 */
export const uiRequirementTypeDetails: UiRequirementTypeDetails[] = [
    /*
    * eventually this should be something fetched from BE. For now, it's hardcoded to make FE slightly eaiser
    * than update all over places, and prepare FE ahead of time in the future we would just make small adjustment when
    * BE will send list of such or similar
    * the name with TprmRequirementType maybe removed and typed as string to reduce maintenance effort,
    * but it will require refactor efforts so may just keep it with enum until BE sends list.
    * or until FE getting tired of updating enum as well
    * */
    { name: TprmRequirementType.aviationLiability, namesInAttribute: [ 'aviation_liability' ], label: 'Aviation Liability', category: UiRequirementTypeCategory.insurance, icon: aviationLiabilityIcon },
    { name: TprmRequirementType.baileesInsurance, namesInAttribute: [ 'bailees_insurance' ], label: 'Bailees Insurance', category: UiRequirementTypeCategory.insurance, icon: baileesInsuranceIcon },
    { name: TprmRequirementType.barberOrHairDesign, namesInAttribute: [ 'barber_and_hair_design' ], label: 'Barber Or Hair Design', category: UiRequirementTypeCategory.withoutDocumentUpload, icon: defaultIcon },
    { name: TprmRequirementType.boatowners, namesInAttribute: [ 'boatowners' ], label: 'Boatowners', category: UiRequirementTypeCategory.insurance, icon: boatownersIcon },
    { name: TprmRequirementType.buildersRisk, namesInAttribute: [ 'builders_risk' ], label: 'Builders Risk', category: UiRequirementTypeCategory.insurance, icon: coverageIconBuildersRisk },
    { name: TprmRequirementType.businessAutoLiability, namesInAttribute: [ 'automobile_liability', 'commercial_auto_liability' ], label: 'Business Auto Liability', category: UiRequirementTypeCategory.insurance, icon: businessAutoIcon },
    { name: TprmRequirementType.cargoLiability, namesInAttribute: [ 'cargo_liability' ], label: 'Cargo Liability', category: UiRequirementTypeCategory.insurance, icon: cargoLiabilityIcon },
    { name: TprmRequirementType.collectivePersonalAccidentInsurance, namesInAttribute: [ 'collective_personal_accident_insurance' ], label: 'Collective Personal Accident Insurance', category: UiRequirementTypeCategory.insurance, icon: collectivePersonalAccidentInsuranceIcon },
    { name: TprmRequirementType.commercialAutoPhysicalDamage, namesInAttribute: [ 'commercial_auto_physical_damage' ], label: 'Commercial Auto Physical Damage', category: UiRequirementTypeCategory.insurance, icon: commercialAutoPhysicalDamageIcon },
    { name: TprmRequirementType.commercialCrimeLiability, namesInAttribute: [ 'commercial_crime', 'comprehensive_crime' ], label: 'Commercial Crime Liability', category: UiRequirementTypeCategory.insurance, icon: commercialCrimeIcon },
    { name: TprmRequirementType.commercialGeneralLiability, namesInAttribute: [ 'commercial_general_liability' ], label: 'Commercial General Liability', category: UiRequirementTypeCategory.insurance, icon: generalLiabilityIcon },
    { name: TprmRequirementType.commercialProperty, namesInAttribute: [ 'commercial_property', 'property_insurance' ], label: 'Commercial Property', category: UiRequirementTypeCategory.insurance, icon: commercialPropertyIcon },
    { name: TprmRequirementType.commercialTrailerInterchange, namesInAttribute: [ 'commercial_trailer_interchange' ], label: 'Commercial Trailer Interchange', category: UiRequirementTypeCategory.insurance, icon: commercialTrailerInterchangeIcon },
    { name: TprmRequirementType.contingentAutoLiability, namesInAttribute: [ 'contingent_auto_liability' ], label: 'Contingent Auto Liability', category: UiRequirementTypeCategory.insurance, icon: contingentAutoLiabilityIcon },
    { name: TprmRequirementType.contingentCargoLiability, namesInAttribute: [ 'contingent_cargo_liability' ], label: 'Contingent Cargo Liability', category: UiRequirementTypeCategory.insurance, icon: contingentCargoLiabilityIcon },
    { name: TprmRequirementType.cosmetologist, namesInAttribute: [ 'cosmetologist' ], label: 'Cosmetologist', category: UiRequirementTypeCategory.withoutDocumentUpload, icon: defaultIcon },
    { name: TprmRequirementType.craneLiability, namesInAttribute: [ 'crane_liability' ], label: 'Crane Liability', category: UiRequirementTypeCategory.insurance, icon: craneLiabilityIcon },
    { name: TprmRequirementType.cropInsurance, namesInAttribute: [ 'crop_insurance' ], label: 'Crop Insurance', category: UiRequirementTypeCategory.insurance, icon: coverageIconCrop },
    { name: TprmRequirementType.cyberLiability, namesInAttribute: [ 'cyber_liability' ], label: 'Cyber Liability', category: UiRequirementTypeCategory.insurance, icon: cyberLiabilityIcon },
    { name: TprmRequirementType.declarationsPage, namesInAttribute: [ 'declarations_page' ], label: 'Declarations Page', category: UiRequirementTypeCategory.nonInsurance, icon: declarationsPageIcon },
    { name: TprmRequirementType.directorsAndOfficersLiability, namesInAttribute: [ 'directors_and_officers_liability' ], label: 'Directors and Officers Liability', category: UiRequirementTypeCategory.insurance, icon: directorsAndOfficersLiabilityIcon },
    { name: TprmRequirementType.dwelling, namesInAttribute: [ 'dwelling' ], label: 'Dwelling', category: UiRequirementTypeCategory.insurance, icon: coverageIconDwelling },
    { name: TprmRequirementType.earthquakeInsurance, namesInAttribute: [ 'earthquake_insurance' ], label: 'Earthquake Insurance', category: UiRequirementTypeCategory.insurance, icon: earthquakeInsuranceIcon },
    { name: TprmRequirementType.employeeDishonesty, namesInAttribute: [ 'employee_dishonesty' ], label: 'Employee Dishonesty', category: UiRequirementTypeCategory.insurance, icon: employeeDishonestyIcon },
    { name: TprmRequirementType.employersLiability, namesInAttribute: [ 'employers_liability' ], label: 'Employers Liability', category: UiRequirementTypeCategory.insurance, icon: employerLiabilityIcon },
    { name: TprmRequirementType.employmentPracticesLiability, namesInAttribute: [ 'employment_practices_liability' ], label: 'Employment Practices Liability', category: UiRequirementTypeCategory.insurance, icon: employmentPracticesLiabilityIcon },
    { name: TprmRequirementType.environmentalLiability, namesInAttribute: [ 'environmental_liability' ], label: 'Environmental Liability', category: UiRequirementTypeCategory.insurance, icon: environmentalLiabilityIcon },
    { name: TprmRequirementType.esthetician, namesInAttribute: [ 'esthetician' ], label: 'Esthetician', category: UiRequirementTypeCategory.withoutDocumentUpload, icon: estheticianLicenseIcon },
    { name: TprmRequirementType.farmowners, namesInAttribute: [ 'farmowners' ], label: 'Farmowners', category: UiRequirementTypeCategory.insurance, icon: coverageIconFarmOwners },
    { name: TprmRequirementType.fleet, namesInAttribute: [ 'fleet' ], label: 'Fleet', category: UiRequirementTypeCategory.insurance, icon: coverageIconFleet },
    { name: TprmRequirementType.floodInsurance, namesInAttribute: [ 'flood_insurance' ], label: 'Flood Insurance', category: UiRequirementTypeCategory.insurance, icon: coverageIconFloodInsurance },
    { name: TprmRequirementType.foodServices, namesInAttribute: [ 'food_services' ], label: 'Food Services', category: UiRequirementTypeCategory.nonInsurance, icon: defaultIcon },
    { name: TprmRequirementType.garageKeepersLiability, namesInAttribute: [ 'garage_keepers_liability' ], label: 'Garage Keepers Liability', category: UiRequirementTypeCategory.insurance, icon: garageKeepersLiabilityIcon },
    { name: TprmRequirementType.garageLiability, namesInAttribute: [ 'garage_liability' ], label: 'Garage Liability', category: UiRequirementTypeCategory.insurance, icon: garageLiabilityIcon },
    { name: TprmRequirementType.holdHarmlessAgreement, namesInAttribute: [ 'hold_harmless_agreement' ], label: 'Hold Harmless Agreement', category: UiRequirementTypeCategory.nonInsurance, icon: holdHarmlessAgreementIcon },
    { name: TprmRequirementType.homeowners, namesInAttribute: [ 'homeowners' ], label: 'Homeowners', category: UiRequirementTypeCategory.insurance, icon: coverageIconHomeOwners },
    { name: TprmRequirementType.hospitality, namesInAttribute: [ 'hospitality' ], label: 'Hospitality', category: UiRequirementTypeCategory.nonInsurance, icon: defaultIcon },
    { name: TprmRequirementType.hullAndMachinery, namesInAttribute: [ 'hull_and_machinery' ], label: 'Hull and Machinery', category: UiRequirementTypeCategory.insurance, icon: hullAndMachineryIcon },
    { name: TprmRequirementType.inlandMarineCommercialAuto, namesInAttribute: [ 'inland_marine_commercial_auto', 'inland_marine' ], label: 'Inland Marine Commercial Auto', category: UiRequirementTypeCategory.insurance, icon: inlandMarineCommercialAutoIcon },
    { name: TprmRequirementType.leasedOrRentedEquipment, namesInAttribute: [ 'leased_or_rented_equipment' ], label: 'Leased or Rented Equipment', category: UiRequirementTypeCategory.insurance, icon: leasedOrRentedEuipmentIcon },
    { name: TprmRequirementType.lifeInsurance, namesInAttribute: [ 'life_insurance' ], label: 'Life Insurance', category: UiRequirementTypeCategory.insurance, icon: coverageIconLifeInsurance },
    { name: TprmRequirementType.liquorLiability, namesInAttribute: [ 'liquor_liability' ], label: 'Liquor Liability', category: UiRequirementTypeCategory.insurance, icon: liquorLiabilityIcon },
    { name: TprmRequirementType.longshoreAndHarborWorkersCompensation, namesInAttribute: [ 'longshore_and_harbor_workers_compensation' ], label: 'Longshore and Harbor Workers Compensation', category: UiRequirementTypeCategory.insurance, icon: longshoreAndHarborWorkersCompensationIcon },
    { name: TprmRequirementType.lossOfIncome, namesInAttribute: [ 'loss_of_income' ], label: 'Loss of Income', category: UiRequirementTypeCategory.insurance, icon: coverageIconLossOfIncome },
    { name: TprmRequirementType.manicuristOrNailTech, namesInAttribute: [ 'manicurist_and_nail_tech' ], label: 'Manicurist Or Nail Tech', category: UiRequirementTypeCategory.withoutDocumentUpload, icon: defaultIcon },
    { name: TprmRequirementType.marineGeneralLiability, namesInAttribute: [ 'marine_general_liability' ], label: 'Marine General Liability', category: UiRequirementTypeCategory.insurance, icon: marineGeneralLiabilityIcon },
    { name: TprmRequirementType.maritimeEmployersLiability, namesInAttribute: [ 'maritime_employers_liability' ], label: 'Maritime Employers Liability', category: UiRequirementTypeCategory.insurance, icon: maritimeEmployersLiabilityIcon },
    { name: TprmRequirementType.massageTherapist, namesInAttribute: [ 'massage_therapist' ], label: 'Massage Therapist', category: UiRequirementTypeCategory.withoutDocumentUpload, icon: massageTherapistLicenseIcon },
    { name: TprmRequirementType.mediaLiability, namesInAttribute: [ 'media_liability' ], label: 'Media Liability', category: UiRequirementTypeCategory.insurance, icon: mediaLiabilityIcon },
    { name: TprmRequirementType.medicalErrorsAndOmissions, namesInAttribute: [ 'medical_errors_and_omissions' ], label: 'Medical Errors and Omissions', category: UiRequirementTypeCategory.insurance, icon: medicalErrorsAndOmissionsIcon },
    { name: TprmRequirementType.nonTruckingLiability, namesInAttribute: [ 'non_trucking_liability' ], label: 'Non-Trucking Liability', category: UiRequirementTypeCategory.insurance, icon: nonTruckingLiabilityIcon },
    { name: TprmRequirementType.occupationalAccident, namesInAttribute: [ 'occupational_accident' ], label: 'Occupational Accident', category: UiRequirementTypeCategory.insurance, icon: occupationalIcon },
    { name: TprmRequirementType.personalLiability, namesInAttribute: [ 'personal_liability' ], label: 'Personal Liability', category: UiRequirementTypeCategory.insurance, icon: personalLiabilityIcon },
    { name: TprmRequirementType.professionalLiability, namesInAttribute: [ 'professional_liability' ], label: 'Professional Liability', category: UiRequirementTypeCategory.insurance, icon: professionalLiabilityIcon },
    { name: TprmRequirementType.privatePassengerAuto, namesInAttribute: [ 'private_passenger_auto' ], label: 'Private Passenger Auto', category: UiRequirementTypeCategory.insurance, icon: privatePassengerAutoIcon },
    { name: TprmRequirementType.productsAndCompletedOperations, namesInAttribute: [ 'products_and_completed_operations' ], label: 'Products and Completed Operations', category: UiRequirementTypeCategory.insurance, icon: productsAndCompletedOperationsIcon },
    { name: TprmRequirementType.proofOfPayment, namesInAttribute: [ 'proof_of_payment' ], label: 'Proof of Payment', category: UiRequirementTypeCategory.nonInsurance, icon: proofOfPaymentIcon },
    { name: TprmRequirementType.protectionAndIndemnity, namesInAttribute: [ 'protection_and_indemnity' ], label: 'Protection and Indemnity', category: UiRequirementTypeCategory.insurance, icon: protectionAndIndemnity },
    { name: TprmRequirementType.publicAndProductsLiability, namesInAttribute: [ 'public_and_products_liability' ], label: 'Public and Products Liability', category: UiRequirementTypeCategory.insurance, icon: publicAndProductsLiabilityIcon },
    { name: TprmRequirementType.riggersLiability, namesInAttribute: [ 'riggers_liability' ], label: 'Riggers Liability', category: UiRequirementTypeCategory.insurance, icon: riggersLiabilityIcon },
    { name: TprmRequirementType.scheduleOfFormsAndEndorsements, namesInAttribute: [ 'schedule_of_forms_and_endorsements' ], label: 'Schedule of Forms and Endorsements', category: UiRequirementTypeCategory.nonInsurance, icon: scheduleOfFormsAndEndorsementsIcon },
    { name: TprmRequirementType.sexualAbuseAndMolestationLiability, namesInAttribute: [ 'sexual_abuse_and_molestation' ], label: 'Sexual Abuse and Molestation Liability', category: UiRequirementTypeCategory.insurance, icon: sexualAbuseAndMolestationLiabilityIcon },
    { name: TprmRequirementType.specialEventUse, namesInAttribute: [ 'special_event_use' ], label: 'Special Event Use', category: UiRequirementTypeCategory.nonInsurance, icon: defaultIcon },
    { name: TprmRequirementType.studentAccidentMedical, namesInAttribute: [ 'student_accident_medical' ], label: 'Student Accident Medical', category: UiRequirementTypeCategory.insurance, icon: studentAccidentMedicalIcon },
    { name: TprmRequirementType.suretyBond, namesInAttribute: [ 'surety_bond' ], label: 'Surety Bond', category: UiRequirementTypeCategory.insurance, icon: suretyBondIcon },
    { name: TprmRequirementType.technologyLiability, namesInAttribute: [ 'technology_liability' ], label: 'Technology Liability', category: UiRequirementTypeCategory.insurance, icon: technologyLiabilityIcon },
    { name: TprmRequirementType.thirdPartyFidelityBond, namesInAttribute: [ '3rd_party_fidelity_bond' ], label: '3rd Party Fidelity Bond', category: UiRequirementTypeCategory.insurance, icon: thirdPartyFidelityBondIcon },
    { name: TprmRequirementType.tourism, namesInAttribute: [ 'tourism' ], label: 'Tourism', category: UiRequirementTypeCategory.nonInsurance, icon: defaultIcon },
    { name: TprmRequirementType.transportation, namesInAttribute: [ 'transportation' ], label: 'Transportation', category: UiRequirementTypeCategory.nonInsurance, icon: defaultIcon },
    { name: TprmRequirementType.umbrellaExcessLiability, namesInAttribute: [ 'umbrella_excess_liability', 'umbrella_liability' ], label: 'Umbrella Excess Liability', category: UiRequirementTypeCategory.insurance, icon: umbrellaExcessIcon },
    { name: TprmRequirementType.w9Form, namesInAttribute: [ 'w_9_form' ], label: 'W9 Form', category: UiRequirementTypeCategory.nonInsurance, icon: w9FormIcon },
    { name: TprmRequirementType.warehouseLegalLiability, namesInAttribute: [ 'warehouse_legal_liability' ], label: 'Warehouse Legal Liability', category: UiRequirementTypeCategory.insurance, icon: warehouseLegalLiabilityIcon },
    { name: TprmRequirementType.watercraft, namesInAttribute: [ 'watercraft' ], label: 'Watercraft', category: UiRequirementTypeCategory.insurance, icon: watercraftIcon },
    { name: TprmRequirementType.workersCompensation, namesInAttribute: [ 'workers_compensation' ], label: 'Workers Compensation', category: UiRequirementTypeCategory.insurance, icon: workersCompensationIcon },
    { name: TprmRequirementType.workersCompensationWaiver, namesInAttribute: [ 'workers_compensation_waiver' ], label: 'Workers Compensation Waiver', category: UiRequirementTypeCategory.nonInsurance, icon: workersCompensationWaiverIcon },
    { name: TprmRequirementType.yachtInsurance, namesInAttribute: [ 'yacht_insurance' ], label: 'Yacht Insurance', category: UiRequirementTypeCategory.insurance, icon: yachtInsuranceIcon },
];
