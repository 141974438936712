import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';

export enum UiRequirementTypeCategory {
    insurance = 'INSURANCE',
    nonInsurance = 'NON_INSURANCE',
    withoutDocumentUpload = 'WITHOUT_DOCUMENT_UPLOAD',
    other = 'OTHER',
}

export interface UiRequirementTypeDetails {
    name: TprmRequirementType;
    namesInAttribute: string[];
    label: string;
    category: UiRequirementTypeCategory;
    icon: string;
}
