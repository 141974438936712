import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
import { range } from 'lodash';
import {
    getFlattenedCustomPropertyNames,
} from '@/modules/entity-management/utils/get-flattened-custom-property-names/getFlattenedCustomPropertyNames';
import { standardColumnNames } from '@/modules/entity-management/utils/csv-sample/constants';
import { generateSampleRow } from '@/modules/entity-management/utils/csv-sample/helpers/generateSampleRow';

export function generateSampleCsvData(insuredFields: CustomProperty[],
    coverageTypes: string[] = []): (string | number)[][] {
    const getCoverageExpirationColumnLabel =
        (coverage: string): string => `Existing ${coverage} Policy Expiration Date`;
    return [
        [
            ...standardColumnNames,
            ...getFlattenedCustomPropertyNames(insuredFields),
            ...coverageTypes.map(getCoverageExpirationColumnLabel),
        ],
        ...range(0, 10).map(() => generateSampleRow(insuredFields, coverageTypes),
        ),
    ];
}
