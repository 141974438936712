import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
import { ListIteratee, Many } from 'lodash';
import orderBy from 'lodash/orderBy';
import JsonSchema, {
    JsonSchemaArray, JsonSchemaBasicObject,
    JsonSchemaType,
    RegularJsonSchema,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import { isCollateralCustomProperty } from '@/utils/isCollateralCustomProperty';
import startCase from 'lodash/startCase';

const customPropertyCollateralOrder = [
    'description',
    'category',
    'uniqueIdentifier',
    'limitRequired',
    'maximumDeductible',
];

export function getCustomPropertySchema(
    customProperties: CustomProperty[],
    customPropertiesSortingIteratees: Many<ListIteratee<CustomProperty>> = (_, index) => index,
    customPropertyOrders: Parameters<typeof orderBy>[2] = [],
): JsonSchema {
    return {
        type: JsonSchemaType.object,
        properties: {
            ...customProperties.reduce((accu, field) => {
                let property;
                if (isCollateralCustomProperty(field)) {
                    property = {
                        ...field.schema,
                        items: {
                            ...(field.schema as any).items,
                            propertiesOrder: customPropertyCollateralOrder,
                        },
                    } as JsonSchemaArray;
                } else {
                    const notSpaceOnlyRegex = '^(?!\\s+$)';
                    property = {
                        ...field.schema,
                        // FIXME: a proper way to validate trimmed string for empty check
                        ...(field.required && (field.schema as RegularJsonSchema).type === 'string' && { pattern: notSpaceOnlyRegex }),
                    };
                }
                if ((field.schema as RegularJsonSchema).type === JsonSchemaType.array && field.required) {
                    (property as JsonSchemaArray).minItems = 1;
                }
                accu[field.key] = property;
                return accu;
            }, {} as JsonSchemaBasicObject['properties']),
        },
        propertiesOrder: orderBy(customProperties, customPropertiesSortingIteratees, customPropertyOrders)
            .map((x) => x.key),
        required: customProperties.filter((x) => x.required).map((x) => x.key),
    };
}

export function getExceptionsSchema(requirementTypes: string[]): JsonSchema {
    const requirementTypeKeyLabels = requirementTypes.map((x) => (
        { key: x, label: startCase(x.toLowerCase().replace(/_/g, ' ')) }),
    );
    return {
        type: JsonSchemaType.object,
        properties: requirementTypeKeyLabels.reduce((accu, requirementType) => ({
            ...accu, [requirementType.key]: {
                type: JsonSchemaType.string,
                format: 'date',
                title: `Existing ${requirementType.label} Policy Expiration Date`,
            },
        }), {} as JsonSchemaBasicObject['properties']),
        propertiesOrder: [ ...requirementTypes ],
        required: [],
    };
}
