import { getAddressPropOrder } from '@/modules/entity-management/utils/get-address-props-order/getAddressPropOrder';
import {
    appendStandardFieldAddressToObjectTypeKeysList,
    prefixStandardFieldAddressCsvKeys,
} from '@/modules/entity-management/utils/prefix-standard-field-object-type-csv-keys/prefixStandardFieldObjectTypeCsvKeys';

function getFlattenedPropName(objName: string, columnName: string, propSeparator?: string): string {
    return columnName.split(`${objName}${propSeparator || '.'}`)[1];
}

/**
 * Combine all flattened props into single object value and storage in a map where the key is the index of the first
 * flattened prop of the object in the values array
 * @param headerKeys Keys of each entity/customProperty property mapped from csv column headers
 * @param values value of each entity/customProperty property
 * @param objectPropIndexMap map contains lists of each object's flattened column indexes within the values array
 * @return map where the key is the first index of a object's first flattened column index and value is all the
 * flattened values combined object
 */
function getCsvValuesIndexReplacementMap(
    headerKeys: string[],
    values: string[],
    objectPropIndexMap: Record<string, number[]>,
): Record<number, object> {
    return Object.keys(objectPropIndexMap).reduce((valuesIndexReplacementMap, objName) => {
        const replacementIndex = objectPropIndexMap[objName][0];
        const replacementValue = objectPropIndexMap[objName].reduce(
            (replacementAcc, i) => {
                replacementAcc[getFlattenedPropName(objName, headerKeys[i])] = values[i];
                return replacementAcc;
            }, {} as Record<string, string>,
        );
        valuesIndexReplacementMap[replacementIndex] = replacementValue;
        return valuesIndexReplacementMap;
    }, {} as Record<number, object>);
}

/**
 * Reconstruct a new value array where flattened object values being combined. for example:
 * values: ['a', 'obj_aVal', 'obj_bVal', 'c'] indexReplacementMap: { 1: {propA: 'obj_aVal', propB: 'obj_bVal'}}
 * skippingIndexes: [2]
 * => ['a', {propA: 'obj_aVal', propB: 'obj_bVal'}, 'c']
 * @param values value of each entity/customProperty property
 * @param indexReplacementMap a map indicates which index of the value array to be replaced with a value in the map
 *                            it's used to replace each object's first prop column value with combined object value
 * @param skippingIndexes a list of indexes of the values array that are object's prop columns, these column values
 *                        will be discarded(after being combined), and only the first index of each object's prop
 *                        column will be kept to replace with combined object value
 * @return array of values where all flattened object columns has been combined into single column
 */
function getValuesWithCombinedObject(
    values: string[],
    indexReplacementMap: Record<number, object>,
    skippingIndexes: number[],
): any[] {
    const newValue = [] as any[];
    values.forEach((val, i) => {
        if (!skippingIndexes.includes(i)) {
            newValue.push(indexReplacementMap[i] ? indexReplacementMap[i] : val);
        }
    });
    return newValue;
}

/**
 * Get indexes of object field's properties mapped by object's key. this is to help later to know which items to be
 * combined into single object value and removed from value list. for example:
 * headerKeys: ['strCol', 'obj.propA', 'obj.propB', 'numCol] objectKeys['obj']
 * => {obj:[1,2]}
 * @param headerKeys Keys of each entity/customProperty property mapped from csv column headers
 * @param objectKeys Keys of object field where they were broken down in to multiple columns in the csv
 * @param propSeparator of object property where they were broken down in to multiple columns in the csv
 * @return indexes of object properties mapped by Object name
 */
export function getCsvObjectPropIndexesMap(
    headerKeys: string[], objectKeys: string[], propSeparator?: string,
): Record<string, number[]> {
    return headerKeys.reduce((acc, header, index) => {
        const objectFieldName = objectKeys.find((name) => header.startsWith(`${name}${propSeparator || '.'}`));
        if (objectFieldName) {
            const indexes = acc[objectFieldName] || [];
            acc[objectFieldName] = [ ...indexes, index ];
        }
        return acc;
    }, {} as Record<string, number[]>);
}

/**
 * takes in CSV headerKeys and values that has object flattened columns and turns into values with combined object
 * for example:
 * headerKeys: ['strCol', 'obj.propA', 'obj.propB', 'numCol] value: ['someStr', 'aVal', 'bVal', 'someNum' ]
 * => ['someStr', '{propA: 'aVal', propB: 'bVal' }', 'someNum']
 * @param headerKeys Keys of each entity/customProperty property mapped from csv column headers
 * @param values value of each entity/customProperty property from csv
 * @param objectKeys Keys of object field where they were broken down in to multiple columns in the csv
 * @return The result new values that has single column for each object property
 */
export function getCsvValuesWithCombinedObjects(
    headerKeys: string[], values: string[], objectKeys: string[],
): any[] {
    // in order to satisfy UX of no object name prefix in csv, instead of adding complexity to the
    // logic of combing data. it's simpler to prefix the known fields with address. keeping the logic consistent
    const moddedHeaderKeys = prefixStandardFieldAddressCsvKeys(headerKeys);
    const moddedObjectKeys = appendStandardFieldAddressToObjectTypeKeysList(objectKeys);
    // knowing all object prop column locations in the values array, so they can be combined into single objects later.
    const objsPropIndexes = getCsvObjectPropIndexesMap(moddedHeaderKeys, moddedObjectKeys);
    // knowing where is the first prop's index of each object, use it as the storage of each combined object
    const indexReplacementMap = getCsvValuesIndexReplacementMap(moddedHeaderKeys, values, objsPropIndexes);
    // skippingIndexes: knowing indexes of the non-storage object props that won't be included in the new values array
    // first index will not be skipped because it will be used as storage to put combined object
    const skippingIndexes = Object.values(objsPropIndexes).flatMap((i) => i.slice(1));
    // replace each object's first prop index with combined object value and remove all other flattened columns
    return getValuesWithCombinedObject(values, indexReplacementMap, skippingIndexes);
}
