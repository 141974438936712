import startCase from 'lodash/startCase';

export function parseFieldValuePerUiConversion(
    fieldName: string,
    fieldValue: unknown,
): any {
    if ((fieldName === 'coverageStatus' || fieldName === 'status') && typeof fieldValue === 'boolean') {
        return fieldValue ? 'Active' : 'Inactive';
    } else if (
        (fieldName === 'coverageStatus' || fieldName === 'status' || fieldName === 'credentialStatus')
        && typeof fieldValue === 'string'
    ) {
        return startCase(fieldValue.toLowerCase());
    }
    return fieldValue;
}
